$(document).ready(function () {
    ("use strict");

    // Datepicker
    $('input.date').datepicker({
        format: "dd-mm-yyyy",
        language: translations.language,
        weekStart: 1
      });

    // Chosen
    $(".chosen-select").chosen({
        placeholder_text_multiple: translations.chosen.placeholderTextMultiple,
        width: "100%"
    });

    // Tooltips
    $('[data-toggle="tooltip"]').tooltip();

    // Custom file inputs
    bsCustomFileInput.init()

    // Execute link via AJAX and refresh page
    $('a.requires-confirmation-and-refreshes').click(function (e) {
        e.preventDefault();
        var button = $(this);

        Swal.fire({
            text: button.attr('data-modal-content'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: "No",
        }).then((result) => {
            if (result.isConfirmed) {
                $.ajax(button.attr('href'), {
                    cache: false,
                })
                    .done(function () {
                        window.location.reload();
                    })
            }
        })
    });

    // Custom modal titles by data attribute on button
    $('div.modal').on('show.bs.modal', function(e) {
        var relatedTarget = $(e.relatedTarget);

        if (!relatedTarget.is('[data-modal-title]')) {
            return;
        }

        $(this).find('.modal-title').text(relatedTarget.attr('data-modal-title'));
    });

    // Load content via AJAX on form load
    $('div.modal').on('show.bs.modal', function(e) {
        var relatedTarget = $(e.relatedTarget);

        if (!relatedTarget.is('[data-ajax-content]')) {
            return;
        }

        var body = $(this).find('.modal-body');
        body.html('<div class="d-flex justify-content-center">\n<div class="spinner-border" role="status"><span class="sr-only">Cargando...</span></div></div>')

        var ajaxUrl = relatedTarget.attr('data-ajax-content');

        $.ajax(ajaxUrl, {
            action: 'get',
        })
            .done(function (response) {
                body.html(response)
                body.find('input.date').datepicker({
                    format: "dd-mm-yyyy",
                    language: translations.language,
                    weekStart: 1
                });
            })
            .fail(function () {
                body.html("Error cargando datos")
            });
    });

    // Custom modal actions for forms
    $('div.modal.modal-form').on('show.bs.modal', function(e) {
        var relatedTarget = $(e.relatedTarget);

        if (!relatedTarget.is('[data-modal-form-action]')) {
            return;
        }

        $(this).find('form').attr('action', relatedTarget.attr('data-modal-form-action'));
    });

    // Load modal form data and reset form to default custom values
    $('div.modal.modal-form').on('show.bs.modal', function(e) {
        var relatedTarget = $(e.relatedTarget);

        if (!relatedTarget.is('[data-modal-form-data]')) {
            return;
        }

        var jThis = $(this);

        var formDataJsonString = relatedTarget.attr('data-modal-form-data');
        var formData = JSON.parse(formDataJsonString);

        jThis.find('form').trigger('reset');
        jThis.find('form input[type=hidden]').val('');

        for (var jsonAttr in formData) {
            if (!formData.hasOwnProperty(jsonAttr)) {
                continue;
            }

            var nameAttrSelector = 'name*="[' + jsonAttr + ']"';

            var possibleInput = jThis.find('input[' + nameAttrSelector + ']');

            if (possibleInput.is('[type="checkbox"]')) {
                possibleInput.prop('checked', formData[jsonAttr]);
            } else {
                possibleInput.val(formData[jsonAttr]);
            }
            jThis.find('textarea[' + nameAttrSelector + ']').val(formData[jsonAttr]);
            jThis.find('select[' + nameAttrSelector + ']').val(formData[jsonAttr]);
        }
    });

    // Form data submit
    $('div.modal.modal-form form').submit(function (e) {
        e.preventDefault();

        var form = $(this);
        var popupContainingForm = form.closest('div.modal');
        var url = form.attr('action');
        var popupButtons = popupContainingForm.find('button');

        popupButtons.prop('disabled', true);
        form.find('.is-invalid').removeClass('is-invalid');

        $.ajax(url, {
            cache: false,
            method: 'post',
            data: form.serialize()
        })
            .done(function () {
                window.location.reload();
            })
            .fail(function (response) {
                if (response.status === 400) {
                    popupContainingForm.find('.modal-body').html(response.responseText);
                    popupContainingForm.animate({ scrollTop: 0 }, 'slow');
                }
            })
            .always(function () {
                popupButtons.prop('disabled', false);
            });
    });

    // Follow link for tabs
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var href = $(this).attr('href');

        if (!href) {
            return;
        }

        window.location.replace(href);
    })

    // Open selected tab if specified
    if (window.location.hash != '' && window.location.hash != '#') {
        var tab = window.location.hash.substr(1);

        $('a[aria-controls="' + tab + '"]').tab('show');
    }

    // Enviar mail token
    const iconDurationMs = 2000;
    const fadeOutDurationMs = 200;

    $('#enviarMailTarjeta').click(function (e) {
        e.preventDefault();

        var that = $(this);
        var parent = that.parent();
        var loadingIcon = parent.find('.loading-icon');
        var okIcon = parent.find('.ok-icon');
        var errorIcon = parent.find('.error-icon');

        okIcon.hide();
        errorIcon.hide()

        that.prop('disabled', true);
        loadingIcon.show();

        var url = that.attr('data-url');

        $.ajax(url, {
            cache: false,
        })
            .done(function () {
                okIcon.show();
                setTimeout(function () {
                    okIcon.fadeOut(fadeOutDurationMs);
                }, iconDurationMs)
            })
            .fail(function () {
                errorIcon.show();

                setTimeout(function () {
                    errorIcon.fadeOut(fadeOutDurationMs);
                }, iconDurationMs)
            })
            .always(function () {
                that.prop('disabled', false);
                loadingIcon.hide()
            });
    });

    // Data de caducitat
    var tipusDocumentField = $('#upload_document_form_tipusDocument');
    var documentPermanentField = $('#upload_document_form_documentPermanent');

    var dataCaducitatField = $('#upload_document_form_dataCaducitat');
    var dataCaducitatLabel = $('label[for="upload_document_form_dataCaducitat"]');
    var dataCaducitatWrapper = dataCaducitatField.parent();

    var disableDataCaducitat = function () {
        dataCaducitatLabel.addClass('text-muted');
        dataCaducitatField.prop('disabled', true);
        dataCaducitatField.val('');
        dataCaducitatWrapper.hide();
    };

    var enableDataCaducitat = function() {
        dataCaducitatLabel.removeClass('text-muted')
        dataCaducitatField.prop('disabled', false);
        dataCaducitatField.val('');
        dataCaducitatWrapper.show();
    };

    const updateStateOfDataCaducitat = function () {
        var caduca = !!parseInt(tipusDocumentField.find('option:selected').attr('data-caducitat'));

        if (caduca) {
            enableDataCaducitat();

            documentPermanentField.prop('disabled', false);
            documentPermanentField.prop('checked', false);
            documentPermanentField.parent().show();
        } else {
            disableDataCaducitat();

            documentPermanentField.prop('disabled', true);
            documentPermanentField.prop('checked', false);
            documentPermanentField.parent().hide();
        }
    }

    updateStateOfDataCaducitat();

    tipusDocumentField.change(function () {
        updateStateOfDataCaducitat();
    });

    documentPermanentField.change(function () {
        if (documentPermanentField.is(':checked')) {
            disableDataCaducitat();
        } else {
            enableDataCaducitat();
        }
    });

    // Suma parcial de donatius
    const updatePartialSum = function () {
        var selectedCheckboxes = $('#tabla-donativos .sum-selected-checkbox:checked');
        var sum = 0;

        selectedCheckboxes.each(function () {
            sum += $(this).data('price');
        });

        var text = '';

        if (sum > 0) {
            text = 'Sum. parcial: ' + sum.toFixed(2) + ' €';
        }

        $('.sum-selected-result').text(text);
    };

    $('.sum-selected-checkbox').click(updatePartialSum);
    updatePartialSum();

    // Formulari enviament certificat donatius/renda
    var enableFieldFunc = function (origin) {
        var originJQuery = $(origin);
        var closestForm = originJQuery.closest('form');

        var destinationSelector = originJQuery.attr('data-enable-field');
        var option = originJQuery.attr('data-enable-field-option');

        if (!destinationSelector || !option) {
            return;
        }

        var destination = closestForm.find(destinationSelector);

        if (!destination.length) {
            return;
        }

        var destinationId = destination.attr('id');
        var destinationLabel = $('[for="' + destinationId + '"]')

        var originValue = originJQuery.val();

        if (originValue === option) {
            destinationLabel.removeClass('text-muted');
            destination.prop('disabled', false);
            destination.val('');
        } else {
            destinationLabel.addClass('text-muted');
            destination.prop('disabled', true);
            destination.val('');
        }
    }

    var $dataEnableField = $('[data-enable-field]');

    $dataEnableField.each(function () {
        var that = this;

        enableFieldFunc(that);

        $(this).closest('form').on('reset', function () {
            setTimeout(function() {
                enableFieldFunc(that);
            }, 5);
        });
    });

    $dataEnableField.change(function(e) {
        enableFieldFunc(e.target);
    });

    // --- EDICIÓ
    var detailsForm = $('#details-form');
    var detailsFormSaveButtonContainer = $('#details-form-save-button-container');
    var detailsFormSubmitButton = detailsFormSaveButtonContainer.find('button');
    var detailsFormSubmitButtonSpinner = detailsFormSaveButtonContainer.find('.spinner-border');

    var disableDetailsFormButton = function () {
        detailsFormSubmitButton.prop('disabled', true);
        detailsFormSubmitButtonSpinner.removeClass('d-none');
    }

    var enableDetailsFormButton = function () {
        detailsFormSubmitButton.prop('disabled', false);
        detailsFormSubmitButtonSpinner.addClass('d-none');
    }

    // Checkboxes imatges redones
    $('.checkbox-round-img').click(function () {
        var checkbox = $(this).closest('div.element').find('.checkbox-round');

        if (checkbox.is(':disabled')) {
            return;
        }

        checkbox.prop("checked", !checkbox.prop("checked")).trigger('change');
    });

    $('.checkbox-round').change(function () {
        let that = $(this);

        var parentDiv = that.closest('div.element');
        var checked = that.prop('checked');

        parentDiv.find('.checkbox-round-img').removeClass('d-none');

        var imageToUse;

        if (checked) {
            imageToUse = parentDiv.find('.checkbox-round-img-on');
        } else {
            imageToUse = parentDiv.find('.checkbox-round-img-off');
        }

        imageToUse.addClass('d-none');
    });

    // Validar duplicats
    var timeoutDuplicatsId = null;

    var duplicatsModal = $('.duplicats-modal');
    var duplicatsModalContent = duplicatsModal.find('.duplicats-modal-content');

    var titularsModal = $('#titulars-modal');
    var addressModal = $('#comprovacion-direccion-modal');

    $('.validar-duplicats').keyup(function () {
        duplicatsModal.addClass("show");
        duplicatsModalContent
            .html('<div class="spinner-border" role="status"><span class="sr-only">Cargando...</span></div>');

        if (timeoutDuplicatsId !== null) {
            clearTimeout(timeoutDuplicatsId);
        }

        timeoutDuplicatsId = setTimeout(function() {
            timeoutDuplicatsId = null;

            var data = $('.validar-duplicats').serializeArray();
            var fieldName = 'idContacte';

            if (data.length) {
                var prefix = data[0].name;
                prefix = prefix.substring(0, prefix.indexOf("["))
                fieldName = prefix + "[" + fieldName + "]";
            }

            data.push({name: fieldName, value: $('#id-contacte').val()});

            var currentRequest = null;

            currentRequest = $.ajax(duplicatsModal.attr('data-action'), {
                cache: false,
                data: data,
                beforeSend: function () {
                    if (currentRequest != null) {
                        currentRequest.abort();
                        duplicatsModal.addClass("show");
                    }
                }
            })
                .done(function (response) {
                    if (response.trim()) {
                        duplicatsModalContent.html(response);
                        duplicatsModal.addClass("show");
                    } else {
                        duplicatsModal.removeClass("show");
                    }
                })
                .fail(function () {
                    duplicatsModalContent.html("Error");
                });
        }, 1000);
    });

    duplicatsModal.find('.close').click(function() {
        duplicatsModal.removeClass("show");
    });

    // Actualitza titulars
    var enableTitulatsCheck = function () {
        detailsForm.attr('data-titulars', "1");
    };

    $('.actualitza-titulars')
        .keydown(enableTitulatsCheck)
        .change(enableTitulatsCheck);

    // Actualitza adreça
    var updateAddressCheck = function () {
        detailsForm.attr('data-address', "1");
    };

    $('.actualitza-adreca').keydown(updateAddressCheck).change(updateAddressCheck);

    // Pos validacions
    var checkPostValidation = function (attrToCheck, modal, errorStr) {
        var check = parseInt(attrToCheck);

        if (!check) {
            return true;
        }

        $.ajax(modal.attr('data-action'), {
            method: 'post',
            data: detailsForm.serialize(),
        })
            .done(function (response) {
                modal.find('.modal-body').html(response);
                modal.modal('show');
            })
            .fail(function () {
                alert(errorStr);
            })
            .always(function() {
                enableDetailsFormButton();
            });

        return false;
    }

    var checkTitularNecessary = function () {
        return checkPostValidation(detailsForm.attr('data-titulars'), titularsModal, "Error cargando titulares");
    };

    var checkAddressNecessary = function () {
        return checkPostValidation(detailsForm.attr('data-address'), addressModal, "Error cargando comprovación de dirección");
    };

    var submitDetailsForm = function () {
        detailsForm.off('submit', checkDetailsFormBeforeSubmit);
        detailsForm.submit();
        detailsForm.on('submit', checkDetailsFormBeforeSubmit);
    }


    titularsModal.find('form').submit(function (e) {
       e.preventDefault();
        var elements = $.map($(this).serializeArray(), function (e) {
            return e.value;
        });

        detailsForm.find('.idTitularsActualitzar').val(elements.join(','));

        disableDetailsFormButton();
        titularsModal.modal('hide');

        var result = checkAddressNecessary();

        if (result) {
            submitDetailsForm();
        }
    });

    addressModal.find('form').submit(function (e) {
        e.preventDefault();

        var checkedCheckboxes = $(this).find('input[type="checkbox"]:checked');

        checkedCheckboxes.each(function () {
            var that = $(this);
            var fieldName = that.attr('name');

            var newValue = addressModal.find('input[name="new-' + fieldName + '"]').val();

            var destField = $('input[name*="[' + fieldName + ']"].actualitza-adreca, select[name*="[' + fieldName + ']"].actualitza-adreca');
            destField.val(newValue);
        });

        disableDetailsFormButton()
        addressModal.modal('hide');

        submitDetailsForm();
    });

    var checkDetailsFormBeforeSubmit = function (e) {
        duplicatsModal.removeClass('show');

        disableDetailsFormButton();

        var result = checkTitularNecessary();

        if (!result) {
            e.preventDefault();
            return;
        }

        result = checkAddressNecessary();

        if (!result) {
            e.preventDefault();
        }
    };

    detailsForm.on('submit', checkDetailsFormBeforeSubmit);

    // Afegir i esborrar caraterístiques i subcaracterístiques
    $('.anadir-caracteristica').click(function () {
        var parent = $(this).closest('div');
        var liNew = parent.find('ul li.new');

        parent.find('ul li.empty').addClass("d-none");

        liNew.removeClass("d-none");
        liNew.find('.spinner-border').addClass('d-none');
        liNew.find('button').prop('disabled', false);
    });

    var prepareAddCaracteristiques = function () {
        $('.anadir-caracteristica-confirm').click(function () {
            var that = $(this);
            var liNew = that.closest('li');
            var ulParent = liNew.closest('ul');

            liNew.find('button').prop('disabled', true);
            liNew.find('.spinner-border').removeClass('d-none');

            $.ajax(that.attr('data-action'), {
                type: "post",
                data: {
                    id: liNew.find('select').val()
                }
            })
                .done(function (response) {
                    liNew.addClass("d-none");
                    ulParent.html(response);
                    prepareAddCaracteristiques();
                })
                .always(function () {
                    liNew.find('button').prop('disabled', false);
                    liNew.find('.spinner-border').addClass('d-none');
                });
        });

        $('a.borrar-caracteristica').click(function (e) {
            e.preventDefault();
            var button = $(this);
            var ulParent = button.closest('ul');

            Swal.fire({
                text: button.attr('data-modal-content'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí',
                cancelButtonText: "No",
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax(button.attr('href'), {
                        cache: false,
                    })
                        .done(function (response) {
                            ulParent.html(response);
                            prepareAddCaracteristiques();
                        })
                }
            })
        });
    }

    prepareAddCaracteristiques();
});
